import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import * as apiUtils from '../../utils/apiUtils';
import useToken from '../../utils/useToken';
import { toast } from 'react-toastify';
import { Row, Col } from 'reactstrap';
import './ClientInfo.css'

function ClientInfo({togglePopup}) {
  const { setToken } = useToken();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nickName, setNickName] = useState('');

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleNickNameChange = (e) => {
    setNickName(e.target.value);
  };

  const handleSave = async () => {
    try {
      const data = await apiUtils.post('/api/notes/clients', {
        'first_name': firstName,
        'last_name': lastName,
        'nick_name': nickName,
      }, navigate, setToken);
      console.log('API Response:', data);
      if (data.id) {
        toast.success("Client created successfully");
        togglePopup()
      }
    } catch (error) {
      console.error('API Error:', error);
      // Handle non-field errors
      if (error.non_field_errors) {
        const errorMessage = error.non_field_errors.join(' ');
        toast.error(errorMessage);
      } else if (error.detail) {
        // Handle generic error detail (e.g., permission denied)
        const errorMessage = error.detail;
        toast.error(errorMessage);
      } else {
        // Handle field-specific validation errors
        Object.keys(error).map(key => `${key.split('_').join(' ')}: ${error[key].join(' ')}`).map(msg => toast.error(msg));
      }
    }
    console.log('First Name:', firstName);
    console.log('Last Name:', lastName);
    console.log("Client's Nickname:", nickName);
  };

  return (
    <>
      {/* <Menu /> */}
      <div className="client-info-container">
        <Row xs="12">
          <Col xs="8"><h3>Add Client</h3></Col>
          <Col xs="4"><><button onClick={togglePopup} className="close-btn btn-client-info">
                <FontAwesomeIcon icon={faTimes} />
              </button></></Col>
        </Row>
        <form>
          <div className="form-group">
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              autoFocus
              onChange={handleFirstNameChange}
              placeholder="Enter First Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={handleLastNameChange}
              placeholder="Enter Last Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="nickName">Client's Nickname:</label>
            <input
              type="text"
              id="nickName"
              value={nickName}
              onChange={handleNickNameChange}
              placeholder="Enter Nickname"
            />
          </div>
          <button className="btn-class btn-client-info" type="button" onClick={handleSave}>
            Save
          </button>
          
        </form>
      </div>
    </>
  );
}

export default ClientInfo;
