import React, { useEffect, useState } from 'react';
import Menu from '../Menu'
import ManagePayments from '../ManagePayments';
import * as apiUtils from '../../utils/apiUtils';
import useProfile from '../../utils/useProfile';
import PaymentComponent from '../PaymentComponent'
import CardDetails from '../CardDetails';
import './AccountPage.css'
function AccountPage() {
  const [planId, setPlanId] = useState('');
  const { profile } = useProfile();
  const [userSetting, setUserSetting] = useState();
  const [subscribeBtnStatus, setSubscribeBtnStatus] = useState(true)
  const freeTierId = process.env.REACT_APP_FREE_TIER_ID;
  const getUserSettings = async () => {
    try {
      const data = await apiUtils.get('/api/user/user_setting');
      console.log('API Response:', data);
      setUserSetting(data)
    } catch (error) {
      console.error('getUserSettings.error', error)
    }
  }
  useEffect(() => {
    getUserSettings();
  }, []);

  useEffect(() => {
    if (userSetting?.customer?.default_card && Object.keys(userSetting?.customer?.default_card).length > 0) {
      setSubscribeBtnStatus(false);
    }
  }, [userSetting]);


  return (
    <div >
      <Menu isPlanActive={true} />
      <div className="account-page">
        {userSetting?.customer?.default_card && Object.keys(userSetting?.customer?.default_card).length > 0 && <CardDetails card={userSetting?.customer?.default_card} />}
        {!planId && <ManagePayments
          setPlanId={setPlanId}
          profile={profile}
          userSetting={userSetting}
          displaySubscribeBtn={subscribeBtnStatus}
          freeTierId={freeTierId}
        />
        }
        {planId && planId !== freeTierId && (
          <PaymentComponent planId={planId} />
        )}
      </div>
    </div>
  );
}

export default AccountPage;
