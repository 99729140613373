import React, { useState } from 'react';
import Loader from '../Loader';
import Menu from '../Menu'
import * as apiUtils from '../../utils/apiUtils';
import './NotePreviewer.css'
import { Link } from 'react-router-dom';

function NotePreviewer(props) {
    const [isLoading, setIsLoading] = useState(false);
    // const [prompt, setPrompt] = useState('');
    const [summary, setSummary] = useState('');

    const fetchSummary = async (summaryInstructions) => {
        setIsLoading(true);
        try {
            const response = await apiUtils.post('/api/user/preview_summary_instructions', { summary_instructions: summaryInstructions });
            setSummary(response.summary);
        } catch (error) {
            console.error('Failed to fetch summary:', error);
            setSummary('Failed to load summary.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        fetchSummary(props.summary);
    };

    return (
        <>
            {/* <Menu isPlanActive={true} /> */}
            
            <div className="note-previewer-container">
            <h2>Note Previewer</h2>
                <p>
                    <Link to="/default-transcript">View Default transcript used for note previewer</Link>
                </p>
                {isLoading ? (<div className='loader-height'><Loader /></div>):
                (<>
                    <form onSubmit={handleSubmit}>
                        {/* <textarea
                            style={{ width: '100%' }}
                            placeholder="Enter your prompt here..."
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            rows="4"
                        />
                        <br /> */}
                        <button className='note-btn' type="submit">Generate Summary</button>
                    </form>
                    <textarea
                        style={{ width: '100%' }}
                        value={summary}
                        readOnly
                        rows={15}
                    // cols="50"
                    />
                </>)}
            </div>
        </>
    );
}

export default NotePreviewer;
