import React, { useEffect, useState } from 'react';
import Menu from '../Menu'
import ManagePayments from '../ManagePayments';
import * as apiUtils from '../../utils/apiUtils';
import PaymentComponent from '../PaymentComponent';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const CheckoutForm = () => {
    const [planId, setPlanId] = useState('');
    const freeTierId = process.env.REACT_APP_FREE_TIER_ID;
    const navigate = useNavigate();

    const processPaymentToken = async (freeTierId) => {
        try {
            const data = await apiUtils.post('/api/user/subscribe', {
                plan_id: freeTierId
            });
            return data
        } catch (error) {
            console.error('API Error:', error);
            return error
        }
    }
    useEffect(() => {
        if (!planId || planId !== freeTierId) return;

        const handleSubscription = async () => {
            try {
                const data = await processPaymentToken(freeTierId);
                toast.success(data.status);
                navigate('/dashboard'); // Redirect to the dashboard
            } catch (error) {
                console.error('Error processing free tier subscription:', error);
            }
        };

        handleSubscription();
    }, [planId, freeTierId, navigate]);

    return (
        <>
            <Menu isPlanActive={false} />
            <div className='notes-list'>
                {!planId && (
                    <ManagePayments setPlanId={setPlanId} displaySubscribeBtn={true} />
                )}
                {planId && planId !== freeTierId && (
                    <PaymentComponent planId={planId} />
                )}
            </div>
        </>
    );
};

export default CheckoutForm;