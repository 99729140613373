import React, { useState, useEffect } from 'react';
import * as apiUtils from '../../utils/apiUtils';
import Menu from '../Menu'
import './ClientList.css';

const ClientList = () => {
    const [clients, setClients] = useState([]);

    const getClientsList = async () => {
        try {
            const data = await apiUtils.get('/api/notes/clients');
            console.log('API Response:', data);
            setClients(data.results)
        } catch (error) {
            console.error('getUserSettings.error', error)
        }
    }

    useEffect(() => {
        getClientsList()
    }, []);

    return (
        <><Menu isPlanActive={true} />
        <div className="client-list-container">
            
            {clients.length > 0 ? <h2>List of Clients</h2> : 'No Clients Added'}
            <div className="client-grid">
                {clients.map((client) => (
                    <div key={client.id} className="client-item">
                        <div className="client-circle">
                        <div>{client?.first_name?.charAt(0)?.toUpperCase() + client?.last_name
                            ?.charAt(0)?.toUpperCase()}</div>
                            {/* {client?.first_name
                                ?.split(' ')
                                ?.map((namePart) => namePart[0])
                                ?.join('')} */}
                        </div>
                        <div className="client-name">{client.first_name}</div>
                    </div>
                ))}
            </div>
        </div>
        </>
    );
};

export default ClientList;
