import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import ClientInfo from '../ClientInfo';
import './ClientProfiles.css';
import * as apiUtils from '../../utils/apiUtils';

const ClientProfiles = () => {
    const navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const addNewClient = () => {
        togglePopup()
        // navigate('/client-info');
    };

    const getClientsList = async () => {
        try {
            const data = await apiUtils.get('/api/notes/clients');
            console.log('API Response:', data);
            setClients(data.results)
        } catch (error) {
            console.error('getUserSettings.error', error)
        }
    }

    useEffect(() => {
        getClientsList()
    }, [showPopup]);
    return (
        <div className="client-profiles-container">
            <Row xs='12'>
                <Col xs='4' className='client-header'>
                    {clients.length > 0 ? 'Clients' : ''}
                </Col>
                <Col xs='3'></Col>
                <Col xs='5' className="button-container-client">
                    <Button className="button-container-client-profile" onClick={addNewClient}>Add Client</Button>
                </Col>
            </Row>
            <div className="client-profiles">
                {clients.map((client) => (
                    <div className="client-circle" key={client.id}>
                        <div>{client?.first_name?.charAt(0)?.toUpperCase() + client?.last_name
                            ?.charAt(0)?.toUpperCase()}</div>
                    </div>
                ))}
            </div>
            <div className='all-clients-header'>
                <a href="/clients">View All Clients</a>
            </div>
            {showPopup && (
                 <div className="popup-overlay">
                 <div className="popup">
                    <ClientInfo togglePopup={togglePopup} />
                 </div>
               </div>
            )}
        </div>
    );
};

export default ClientProfiles;
